import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDaP5jOMwvnyPK5x4yeFTffP6ocUiNvtnY",
  authDomain: "vision-virtual-6c32e.firebaseapp.com",
  projectId: "vision-virtual-6c32e",
  storageBucket: "vision-virtual-6c32e.appspot.com",
  messagingSenderId: "306381595672",
  appId: "1:306381595672:web:136cf7eebc1b4252fecf6d",
  measurementId: "G-4XMMZ1V9HT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app')
