<template>
  <div>
    <div :class="!$vuetify.display.smAndDown ? 'main1' : 'main2'">
      <v-container>
        <v-row justify="start" v-if="!$vuetify.display.smAndDown">
          <v-col align="start" class="text-info">
            <p> <a href="tel:+27213935063" style="text-decoration: none;" class="text-white"> <v-icon size="sm">mdi-phone</v-icon> +27 21 393 5063 </a>
              &nbsp; &nbsp; &nbsp; &nbsp; <a href="mailto:info@virtualedgecreatives.com" style="text-decoration: none;" class="text-white"> <v-icon size="sm">mdi-email-outline</v-icon> info@virtualedgecreatives.com </a>
            </p>
          </v-col>
          <v-col align="end" class="text-white">
            <a class="text-white" href="https://www.instagram.com/virtualedgecreatives/" target="_blank" style="text-decoration: none;"><v-icon class="mr-2">mdi-instagram</v-icon></a>
            <a class="text-white" href="https://www.facebook.com/profile.php?id=61558046167085" target="_blank" style="text-decoration: none;"><v-icon class="mr-2">mdi-facebook</v-icon></a>
            <a class="text-white" href="https://www.linkedin.com/in/virtual-edge-creatives-575516305/" target="_blank" style="text-decoration: none;"><v-icon>mdi-linkedin</v-icon></a>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.display.smAndDown">
          <v-col align="end" class="text-white">
            <a class="text-white" href="https://www.instagram.com/virtualedgecreatives/" target="_blank" style="text-decoration: none;"><v-icon class="mr-2">mdi-instagram</v-icon></a>
            <a class="text-white" href="https://www.facebook.com/profile.php?id=61558046167085" target="_blank" style="text-decoration: none;"><v-icon class="mr-2">mdi-facebook</v-icon></a>
            <a class="text-white" href="https://www.linkedin.com/in/virtual-edge-creatives-575516305/" target="_blank" style="text-decoration: none;"><v-icon>mdi-linkedin</v-icon></a>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align="center" class="text-white">
            <v-img :width="$vuetify.display.smAndDown ? '300' : '200'" contain src="@/assets/eagle.png" alt="Virtual Edge Creatives"/>
            <h1 :style="$vuetify.display.smAndDown ? 'font-size: 45px; line-height: 55px;' : 'font-size: 45px;'" :class="$vuetify.display.smAndDown ? 'mt-n12' : 'mt-n12'"> Virtual Edge Creatives </h1>
            <p class="text-white" :style="$vuetify.display.smAndDown ? 'font-size: 20px; margin-top: 0.5cm; margin-bottom: 0.5cm;' : 'font-size: 20px;'">Making your life easier with 
              <br v-if="!$vuetify.display.smAndDown"/> the following services </p>
          </v-col>
        </v-row>
        </v-container>
        <v-container fluid>
          <v-row justify="center" v-if="!$vuetify.display.smAndDown">
            <v-col md="3" v-for="item in items" :key="item.title">
              <v-card 
              color="primary" 
              elevation="10"
              rounded="0"
              height="310" 
              class="pt-2 text-info"
              style="border: 1px solid #757575"
              >
                <p><v-card-title>
                  <v-icon>{{ item.icon }}</v-icon> {{ item.title }}
                </v-card-title></p>
                <v-card-subtitle>This package includes: </v-card-subtitle>
                <v-card-text class="text-white">
                  <ul class="ml-4">
                    <li class="li">{{ item.item1 }}</li>
                    <li class="li">{{ item.item2 }}</li>
                    <li class="li">{{ item.item3 }}</li>
                    <li class="li" v-if="item.item4">{{ item.item4 }}</li>
                    <li class="li" v-if="item.item5">{{ item.item5 }}</li>
                    <li class="li" v-if="item.item6">{{ item.item6 }}</li>
                    <li class="li" v-if="item.item7">{{ item.item7 }}</li>
                    <li v-if="item.item8">{{ item.item8 }}</li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row v-if="!$vuetify.display.smAndDown">
            <v-col align="center">
              <v-card href="#getQuote" elevation="10" hover width="200" class="py-4"> <p style="font-size: 20px;"> Get a quote <v-icon class="text-primary">mdi-send-circle</v-icon></p> </v-card>
            </v-col>
          </v-row>
          <v-row v-if="$vuetify.display.smAndDown" style="margin-bottom: 2cm;">
            <v-col align="center">
              <v-card href="#services" elevation="10" hover width="100%" class="py-3 mt-n10"> <p style="font-size: 30px;"> Our Services <v-icon class="text-primary">mdi-cog</v-icon></p> </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container fluid style="background: linear-gradient(125deg, #F5F5F5, #F5F5F5, #BDBDBD, #0C201B)">
        <v-container :style="$vuetify.display.smAndDown ? 'display: block;' : ''">
          <v-row>
            <v-col class="text-secondary">
              <h1 style="margin-top: 0.5cm; font-size: 45px;">About Us </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>
                At our core, we are a dynamic team of highly skilled professionals dedicated to providing top-notch virtual services to businesses worldwide. 
                Founded by Ruth, a seasoned expert with over 20 years of experience under various hats as a personal assistant, marketing specialist, 
                and graphic designer, our company is built on a foundation of expertise and a passion for excellence. <br/><br/>
                Ruth has carefully assembled a team of talented individuals, each bringing their unique skills and specialties to the table. 
                From virtual assistants who seamlessly handle administrative tasks to graphic designers who create visually stunning designs, our team has you covered. <br/><br/>
                Our copywriters craft compelling content that captivates your audience, while our meticulous proofreaders ensure every word is polished to perfection. 
                Our web development and hosting services provide a robust online presence, ensuring your business thrives in the digital realm. <br/><br/>
                While we pride ourselves on our virtual capabilities, allowing us to serve clients globally with convenience and efficiency, we understand 
                that some circumstances may require an in-person touch. In such cases, our team is prepared to travel to your location, providing the same exceptional 
                service you've come to expect from us. <br/><br/>
                With our unwavering commitment to quality and customer satisfaction, we strive to be your trusted partner, streamlining your business operations and 
                empowering you to focus on what truly matters – growing your company.
              </p>
            </v-col>
          </v-row>
          <div id="services"></div>
        <v-row justify="center" v-if="$vuetify.display.smAndDown">
          <v-col class="text-secondary">
            <h1 style="margin-top: 0.5cm; margin-bottom: 0.5cm; font-size: 45px;">Our Services</h1>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="$vuetify.display.smAndDown" style="display: block;">
          <v-col v-for="item in items" :key="item.title">
            <v-card 
            color="primary" 
            elevation="10"
            class="py-4 text-info card1"
            >
            <p class="card-title px-4" style="font-weight: bold;"><v-icon>{{ item.icon }}</v-icon></p>
            <p class="card-title px-4" style="font-weight: 500;"> {{ item.title }} </p>
            <v-card-subtitle class="card-subtitle">This package includes: </v-card-subtitle>
            <v-card-text class="text-white">
              <ul class="ml-4">
                <li class="list">{{ item.item1 }}</li>
                <li class="list">{{ item.item2 }}</li>
                <li class="list">{{ item.item3 }}</li>
                <li class="list" v-if="item.item4">{{ item.item4 }}</li>
                <li class="list" v-if="item.item5">{{ item.item5 }}</li>
                <li class="list" v-if="item.item6">{{ item.item6 }}</li>
                <li class="list" v-if="item.item7">{{ item.item7 }}</li>
                <li class="list" v-if="item.item8">{{ item.item8 }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.display.smAndDown">
        <v-col align="center">
          <v-card href="#getQuote" elevation="10" hover width="100%" class="py-5 mt-4 mb-12"> <p style="font-size: 30px;"> Get a quote <v-icon class="text-primary icon">mdi mdi-arrow-down-circle</v-icon></p> </v-card>
        </v-col>
      </v-row>
      <div id="getQuote"></div>
      <v-row style="margin-top: 2cm;" v-if="!$vuetify.display.smAndDown">
        <v-col class="text-secondary">
          <h1 style="font-size: 45px;">Get a Quote </h1>
        </v-col>
      </v-row>
      <v-row :style="$vuetify.display.smAndDown ? 'display: block;' : ''">
        <v-col md="6">
          <v-text-field
          variant="solo-inverted"
          flat
          rounded
          prepend-inner-icon="mdi-account-circle"
          placeholder="Full name"
          type="text"
          v-model="fullName"
          ></v-text-field>
          <v-text-field
          variant="solo-inverted"
          flat
          rounded
          prepend-inner-icon="mdi-email"
          placeholder="Email address"
          type="email"
          v-model="email"
          ></v-text-field>
          <v-text-field
          variant="solo-inverted"
          flat
          rounded
          prepend-inner-icon="mdi-phone"
          placeholder="Mobile Number"
          v-model="phoneNumber"
          ></v-text-field>
          <v-select
          variant="solo-inverted"
          flat
          rounded
          prepend-inner-icon="mdi-cog"
          label="Select a service"
          :items="services"
          v-model="selectedService"
          ></v-select>
          <v-textarea
          variant="solo-inverted"
          flat
          prepend-inner-icon="mdi-message-reply-text"
          placeholder="Please provide us with a detailed description of the service that you need, and we will take it from here."
          v-model="message"
          ></v-textarea>

          <v-alert
            v-if="errorMessage != ''"
            border="start"
            color="error"
            icon="$error"
            title="Error Message:"
            :text="errorMessage"
            class="mb-8"
          ></v-alert>
          <v-alert
            v-if="warningMessage != ''"
            border="start"
            closable
            close-label="close message"
            color="warning"
            icon="$warning"
            title="Warning:"
            :text="warningMessage"
            class="mb-8"
          ></v-alert>
          <v-alert
            v-if="successMessage != ''"
            border="start"
            closable
            close-label="close message"
            color="success"
            icon="$success"
            title="Success:"
            :text="successMessage"
            class="mb-8"
          ></v-alert>

          <v-btn
            block
            height="50"
            elevation="10"
            style="border-radius: 30px;"
            class="bg-primary text-white"
            v-on:click="submit"
            :disabled="loading"
            >{{loading ? 'Processing' : 'Submit'}} 
            <v-icon large class="ml-4" v-if="!loading">mdi-send</v-icon> 
            <v-img v-if="loading" class="ml-4" width="30" src="@/assets/loading.gif"/>
          </v-btn>
        </v-col>
        <v-col class="bg2"></v-col>
      </v-row>
      <v-row style="margin-top: 2cm;">
        <v-col align="center" :class="$vuetify.display.smAndDown ? 'text-white' : 'text-primary'">
          <h1>Get in touch with us</h1>
        </v-col>
      </v-row>
      <v-row :style="$vuetify.display.smAndDown ? 'display: block;' : ''">
        <v-col align="center">
          <v-icon :class="$vuetify.display.smAndDown ? 'text-info' : 'text-primary'">mdi-email</v-icon>
          <a href="mailto:info@virtualedgecreatives.com" style="text-decoration: none;" :class="$vuetify.display.smAndDown ? 'text-info' : 'text-primary'"> info@virtualedgecreatives.com </a>
        </v-col>
        <v-col align="center">
          <v-icon :class="$vuetify.display.smAndDown ? 'text-info' : 'text-primary'">mdi-phone</v-icon>
          <a href="tel:+27213935063" style="text-decoration: none;" :class="$vuetify.display.smAndDown ? 'text-info' : 'text-primary'"> +27 21 393 5063 </a>
        </v-col>
        <v-col align="center" :class="$vuetify.display.smAndDown ? 'text-info' : 'text-primary'">
          <a class="text-primary" href="https://www.instagram.com/virtualedgecreatives/" target="_blank" style="text-decoration: none;"><v-icon class="mr-2">mdi-instagram</v-icon></a>
          <a class="text-primary" href="https://www.facebook.com/profile.php?id=61558046167085" target="_blank" style="text-decoration: none;"><v-icon class="mr-2">mdi-facebook</v-icon></a>
          <a class="text-primary" href="https://www.linkedin.com/in/virtual-edge-creatives-575516305/" target="_blank" style="text-decoration: none;"><v-icon>mdi-linkedin</v-icon></a>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';


const firebaseConfig = {
  apiKey: "AIzaSyDaP5jOMwvnyPK5x4yeFTffP6ocUiNvtnY",
  authDomain: "vision-virtual-6c32e.firebaseapp.com",
  projectId: "vision-virtual-6c32e",
  storageBucket: "vision-virtual-6c32e.appspot.com",
  messagingSenderId: "306381595672",
  appId: "1:306381595672:web:136cf7eebc1b4252fecf6d",
  measurementId: "G-4XMMZ1V9HT"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);


export default {
  name: 'Home',
  data(){
    return{
      items: [
        {
          title: 'Virtual Assistance', icon: 'mdi-monitor', item1: 'Administrative Support', item2: 'Calendar Management', item3: 'Social Media Management', item4: 'Email Management', item5: 'Bookeeping', item6: 'Research', item7: 'Web Services'
        },
        {
          title: 'Copywriting and Editing', icon: 'mdi-draw', item1: 'Website Copy', item2: 'Social Media Posts', item3: 'Email Marketing', item4: 'Advertising Campaigns', item5: 'Product Descriptions', item6: 'Articles', item7: 'Editing', item8: 'Proofreading'
        },
        {
          title: 'Graphic Design', icon: 'mdi-palette', item1: 'Logo Design', item2: 'Print Design', item3: 'Packaging Design', item4: 'Website Design', item5: 'Advertising Design', item6: 'Presentation Design', item7: 'Videography'
        },
        {
          title: 'Web Dev & Hosting', icon: 'mdi-web', item1: 'Web Development', item2: 'Website Design', item3: 'Hosting'
        }
      ],
      services: ['Virtual Assistance', 'Copywriting', 'Graphic Design', 'Web Development', 'Hosting'],
      selectedService: '',
      fullName: '',
      email: '',
      phoneNumber: '',
      message: '',
      errorMessage: '',
      successMessage: '',
      warningMessage: '',
      loading: false

      

    }
  },
  methods:{
    async submit(){
      if(
        this.fullName == '' || this.email == '' || this.phoneNumber == '' || this.selectedService == '' || this.message == ''){
        this.errorMessage = 'Please make sure to fill in all the fields.';
      }
      else{
        this.loading = true;
        this.errorMessage = '';

        const sendMail = httpsCallable(functions, 'sendMail');
        await sendMail({
          fullName: this.fullName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          selectedService: this.selectedService,
          message: this.message
        })
        .then(async (response) => {
          let res = await response.data
          console.log(res);
          //make fields empty
          this.fullName = '';
          this.email = '';
          this.phoneNumber = '';
          this.selectedService = '';
          this.message = '';
          this.successMessage = 'Quote enquiry successfully sent! We will get back to you within 24 hours.\nThank you.';
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage = 'Something went wrong. We understand this is not convinient.\nAlternatively, please contact us on 076 547 0110';
          this.loading = false;
        })
        
      }
    }

  },
  mounted(){

  }
}
</script>
<style scoped>
/*rgb(85, 85, 85)*/
.main1{
  background: url('@/assets/pict1.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(85, 85, 85);
  background-blend-mode: soft-light;
  background-size: cover;
}
.main2{
  background: url('@/assets/pict1.png');
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(85, 85, 85, 85);
  background-blend-mode: soft-light;
  background-size: cover;
}
.bg2{
  background: url('@/assets/pict2.png');
  background-attachment: scroll;
  background-position: right;
  background-size: cover;
  height: 540px;
}
.li{
  padding: 3px;
}
.list{
  padding: 8px;
  font-size: 18px;
  line-height: 28px;
}
.card-title{
  font-size: 26px;
}
.card-subtitle{
  font-size: 16px;
}

</style>
