// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

let customTheme = {
  dark: false,
  colors:{
    primary: '#0C201B',
    secondary: '#757575',
    info: '#BDBDBD'
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme
    },
  },
})
